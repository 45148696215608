import revive_payload_client_tBSR4ILjlO from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wz7bCqIoXm from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YL1hqkYoyQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_cembJ4nMO5 from "/vercel/path0/node_modules/.pnpm/nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.4_rollup@4.18.0_vue@3.4.27_typescript@5.6.2__webpack-sources@3.2.3/node_modules/nuxt-sentry/dist/runtime/sentry.client.mjs";
import supabase_client_UE0iK2fsHI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import check_outdated_build_client_UxOR5hgl4D from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_tDIdhG7qW7 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.18.0_typescript@5.6.2_vue@3.4.27_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/fastchange/.nuxt/components.plugin.mjs";
import prefetch_client_h3eE2wkg0m from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Krx4fHFLFL from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_h3swn6x4wf56eybtuybw3f4u6i/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_JQMKQpPdbZ from "/vercel/path0/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.13_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3_webpack@5.91.0_esbuild@0.21.5_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import _01_plugin_W5TImTnopn from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.29.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@20.14.2_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/fastchange/.nuxt/unocss.mjs";
import slideovers_2LnoSBdmPt from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.3_rollup@4.18.0_vite@5.3.1_@types+_wwk3rx7ddk2dimaxstvdo7tmdu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_kBr6eoQBBL from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.3_rollup@4.18.0_vite@5.3.1_@types+_wwk3rx7ddk2dimaxstvdo7tmdu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_XP88Y5XWD5 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.3_rollup@4.18.0_vite@5.3.1_@types+_wwk3rx7ddk2dimaxstvdo7tmdu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_97yaQlL22v from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_PvSGU51mKM from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_XIZCskOXXg from "/vercel/path0/layers/base/plugins/auto-animate.ts";
import chart_client_bcfhrmN7hg from "/vercel/path0/layers/base/plugins/chart.client.ts";
import use_head_sEf1F6ycmZ from "/vercel/path0/layers/base/plugins/use-head.ts";
import bottomSheet_client_C55D02kKcz from "/vercel/path0/layers/components/fubex-components/plugins/bottomSheet.client.ts";
import validation_H6BESNSSPe from "/vercel/path0/layers/components/fubex-components/plugins/validation.ts";
import vue_toastification_client_OdKXnwPIgP from "/vercel/path0/layers/components/fubex-components/plugins/vue-toastification.client.ts";
import _03_define_6UVxegxE6W from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.29.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@20.14.2_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  revive_payload_client_tBSR4ILjlO,
  unhead_wz7bCqIoXm,
  router_YL1hqkYoyQ,
  sentry_client_cembJ4nMO5,
  supabase_client_UE0iK2fsHI,
  check_outdated_build_client_UxOR5hgl4D,
  plugin_vue3_tDIdhG7qW7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h3eE2wkg0m,
  plugin_Krx4fHFLFL,
  plugin_JQMKQpPdbZ,
  _01_plugin_W5TImTnopn,
  unocss_MzCDxu9LMj,
  slideovers_2LnoSBdmPt,
  modals_kBr6eoQBBL,
  colors_XP88Y5XWD5,
  plugin_client_97yaQlL22v,
  chunk_reload_client_PvSGU51mKM,
  auto_animate_XIZCskOXXg,
  chart_client_bcfhrmN7hg,
  use_head_sEf1F6ycmZ,
  bottomSheet_client_C55D02kKcz,
  validation_H6BESNSSPe,
  vue_toastification_client_OdKXnwPIgP,
  _03_define_6UVxegxE6W
]