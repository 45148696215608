import { default as email_45verifiedOEGNMLiS1uMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/email-verified.vue?macro=true";
import { default as faqCZNlWjHumEMeta } from "/vercel/path0/layers/modules/fubex-pages/pages/faq.vue?macro=true";
import { default as indexfJqbXK8UP2Meta } from "/vercel/path0/layers/modules/fubex-home/pages/index.vue?macro=true";
import { default as _91userId_93mcHhgwm1bvMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/invite/[userId].vue?macro=true";
import { default as kontaktzTjSsNz2qMMeta } from "/vercel/path0/layers/modules/fubex-pages/pages/kontakt.vue?macro=true";
import { default as kybtykhxFzuXDMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/kyb.vue?macro=true";
import { default as login0CAY3R3TM3Meta } from "/vercel/path0/layers/modules/fubex-auth/pages/login.vue?macro=true";
import { default as logout4UhjsdEf4TMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/logout.vue?macro=true";
import { default as nakup_45kryptomen_45na_45firmuYHbAWvHOE7Meta } from "/vercel/path0/layers/modules/fubex-pages/pages/nakup-kryptomen-na-firmu.vue?macro=true";
import { default as o_45nasciA48n1CtwMeta } from "/vercel/path0/apps/fastchange/pages/o-nas.vue?macro=true";
import { default as platba_45faktur_45kryptomenamiMYWfuA9nmKMeta } from "/vercel/path0/layers/modules/fubex-pages/pages/platba-faktur-kryptomenami.vue?macro=true";
import { default as bank_45accountsCEFxJ4x8WhMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/bank-accounts.vue?macro=true";
import { default as change_45passwordaYg8Y21W9sMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/change-password.vue?macro=true";
import { default as indexBgHzhQsNCfMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/index.vue?macro=true";
import { default as verifynMgjgqchhuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/verify.vue?macro=true";
import { default as accountxT2seSerxBMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account.vue?macro=true";
import { default as bank_45accountsAtXfGwxlLdMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue?macro=true";
import { default as _91id_93jkz3ACVgfPMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue?macro=true";
import { default as indexDt2iL7IAEJMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue?macro=true";
import { default as chargesCslMSoduU0Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue?macro=true";
import { default as exchange_45office_45ownerKMajNheYEoMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office-owner.vue?macro=true";
import { default as indexpdLofxmh4kMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue?macro=true";
import { default as kyb2uEk8nTt4NMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue?macro=true";
import { default as _91id_93AFPGGD6FdDMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue?macro=true";
import { default as indexhZBvy7r2suMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue?macro=true";
import { default as overlimitJxVyx19wM1Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue?macro=true";
import { default as statisticssZoYIpuSzuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue?macro=true";
import { default as transactionsavQgegseq4Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue?macro=true";
import { default as _91id_93083RisL263Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue?macro=true";
import { default as indexOwnxsORKUnMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue?macro=true";
import { default as adminq5aC74u6KBMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue?macro=true";
import { default as indexzJpdxfXJ5pMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/index.vue?macro=true";
import { default as rewardsQqLFjCQAGJMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/rewards.vue?macro=true";
import { default as withdrawalsvppnnnmfIIMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/withdrawals.vue?macro=true";
import { default as bonusbrvmpruqgkMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus.vue?macro=true";
import { default as exchange7c3T6TD4u3Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/exchange.vue?macro=true";
import { default as history2zHqZDjg7xMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/history.vue?macro=true";
import { default as indexmpq3T4LE2UMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/index.vue?macro=true";
import { default as chargesfugUAF14EuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/charges.vue?macro=true";
import { default as indexGP8VZusDIUMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/index.vue?macro=true";
import { default as orders2b4VlISxpuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/orders.vue?macro=true";
import { default as transactions9aYnKbcNXzMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/transactions.vue?macro=true";
import { default as usersnnC9YYhwWWMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/users.vue?macro=true";
import { default as inspectoriUqJe6hPJEMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector.vue?macro=true";
import { default as _91id_930kRgfn0FCcMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/orders/[id].vue?macro=true";
import { default as overviewUlCjv3VDeHMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/overview.vue?macro=true";
import { default as verify_45resultCVW43beFqrMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/verify-result.vue?macro=true";
import { default as profileVFLmNfYR3cMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile.vue?macro=true";
import { default as signup_45successQZWtvdzDqmMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/signup-success.vue?macro=true";
import { default as smenarensky_45softwarem544Pc6NGZMeta } from "/vercel/path0/layers/modules/fubex-pages/pages/smenarensky-software.vue?macro=true";
import { default as alertjPl14z56LuMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/alert.vue?macro=true";
import { default as badgeYGMhaGTqYmMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/badge.vue?macro=true";
import { default as buttondbbCr3KmjlMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/button.vue?macro=true";
import { default as checkboxeL02HSl9TDMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/checkbox.vue?macro=true";
import { default as dialog2KHlRwcZEPMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/dialog.vue?macro=true";
import { default as file_45input5upskOLlDcMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/file-input.vue?macro=true";
import { default as iconznCjaWAWCmMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/icon.vue?macro=true";
import { default as indexGWnM24V4flMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/index.vue?macro=true";
import { default as inputaYpAWLtl0wMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/input.vue?macro=true";
import { default as labelwHenBjYQpdMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/label.vue?macro=true";
import { default as radioX0mhCkiiuzMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/radio.vue?macro=true";
import { default as selectKKYBwS5HiIMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/select.vue?macro=true";
import { default as shadowsEp6QaRFBuhMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/shadows.vue?macro=true";
import { default as indexHeUbvog5ZqMeta } from "/vercel/path0/layers/components/fubex-components/pages/styleguide/theme/index.vue?macro=true";
import { default as update_45passwordaASOoFSWapMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/update-password.vue?macro=true";
import { default as component_45stubW0C3hXfj3RMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubW0C3hXfj3R } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "email-verified",
    path: "/email-verified",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/email-verified.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/layers/modules/fubex-home/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-userId",
    path: "/invite/:userId()",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/invite/[userId].vue").then(m => m.default || m)
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: "kyb",
    path: "/kyb",
    meta: kybtykhxFzuXDMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/kyb.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "nakup-kryptomen-na-firmu",
    path: "/nakup-kryptomen-na-firmu",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/nakup-kryptomen-na-firmu.vue").then(m => m.default || m)
  },
  {
    name: "o-nas",
    path: "/o-nas",
    component: () => import("/vercel/path0/apps/fastchange/pages/o-nas.vue").then(m => m.default || m)
  },
  {
    name: "platba-faktur-kryptomenami",
    path: "/platba-faktur-kryptomenami",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/platba-faktur-kryptomenami.vue").then(m => m.default || m)
  },
  {
    name: profileVFLmNfYR3cMeta?.name,
    path: "/profile",
    meta: profileVFLmNfYR3cMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: accountxT2seSerxBMeta?.name,
    path: "account",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-account-bank-accounts",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: "profile-account-change-password",
    path: "change-password",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: "profile-account",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-account-verify",
    path: "verify",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: adminq5aC74u6KBMeta?.name,
    path: "admin",
    meta: adminq5aC74u6KBMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-admin-bank-accounts",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-bank-id",
    path: "bank/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-bank",
    path: "bank",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-charges",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-exchange-office-owner",
    path: "exchange-office-owner",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office-owner.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin",
    path: "",
    meta: indexpdLofxmh4kMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-kyb",
    path: "kyb",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-orders-id",
    path: "orders/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-orders",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-overlimit",
    path: "overlimit",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-statistics",
    path: "statistics",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-users-id",
    path: "users/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-admin-users",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bonusbrvmpruqgkMeta?.name,
    path: "bonus",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-bonus",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonus-rewards",
    path: "rewards",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/rewards.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonus-withdrawals",
    path: "withdrawals",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/withdrawals.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profile-exchange",
    path: "exchange",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/exchange.vue").then(m => m.default || m)
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/history.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: inspectoriUqJe6hPJEMeta?.name,
    path: "inspector",
    meta: inspectoriUqJe6hPJEMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-inspector-charges",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/charges.vue").then(m => m.default || m)
  },
  {
    name: "profile-inspector",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-inspector-orders",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/orders.vue").then(m => m.default || m)
  },
  {
    name: "profile-inspector-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/transactions.vue").then(m => m.default || m)
  },
  {
    name: "profile-inspector-users",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profile-orders-id",
    path: "orders/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-overview",
    path: "overview",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/overview.vue").then(m => m.default || m)
  },
  {
    name: "profile-verify-result",
    path: "verify-result",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/verify-result.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "signup-success",
    path: "/signup-success",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/signup-success.vue").then(m => m.default || m)
  },
  {
    name: "smenarensky-software",
    path: "/smenarensky-software",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/smenarensky-software.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-alert",
    path: "/styleguide/alert",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/alert.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-badge",
    path: "/styleguide/badge",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/badge.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-button",
    path: "/styleguide/button",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/button.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-checkbox",
    path: "/styleguide/checkbox",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/checkbox.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-dialog",
    path: "/styleguide/dialog",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/dialog.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-file-input",
    path: "/styleguide/file-input",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/file-input.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-icon",
    path: "/styleguide/icon",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/icon.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/index.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-input",
    path: "/styleguide/input",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/input.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-label",
    path: "/styleguide/label",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/label.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-radio",
    path: "/styleguide/radio",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/radio.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-select",
    path: "/styleguide/select",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/select.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-shadows",
    path: "/styleguide/shadows",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/shadows.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-theme",
    path: "/styleguide/theme",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/theme/index.vue").then(m => m.default || m)
  },
  {
    name: "update-password",
    path: "/update-password",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: "localized-email-verified",
    path: "/:locale(en)/email-verified",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/email-verified.vue").then(m => m.default || m)
  },
  {
    name: "localized-faq",
    path: "/:locale(en)/faq",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "localized-index",
    path: "/:locale(en)",
    component: () => import("/vercel/path0/layers/modules/fubex-home/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "localized-invite-userId",
    path: "/:locale(en)/invite/:userId()",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/invite/[userId].vue").then(m => m.default || m)
  },
  {
    name: "localized-kontakt",
    path: "/:locale(en)/kontakt",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: "localized-kyb",
    path: "/:locale(en)/kyb",
    meta: kybtykhxFzuXDMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/kyb.vue").then(m => m.default || m)
  },
  {
    name: "localized-login",
    path: "/:locale(en)/login",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "localized-logout",
    path: "/:locale(en)/logout",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "localized-nakup-kryptomen-na-firmu",
    path: "/:locale(en)/nakup-kryptomen-na-firmu",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/nakup-kryptomen-na-firmu.vue").then(m => m.default || m)
  },
  {
    name: "localized-o-nas",
    path: "/:locale(en)/o-nas",
    component: () => import("/vercel/path0/apps/fastchange/pages/o-nas.vue").then(m => m.default || m)
  },
  {
    name: "localized-platba-faktur-kryptomenami",
    path: "/:locale(en)/platba-faktur-kryptomenami",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/platba-faktur-kryptomenami.vue").then(m => m.default || m)
  },
  {
    name: "localized-signup-success",
    path: "/:locale(en)/signup-success",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/signup-success.vue").then(m => m.default || m)
  },
  {
    name: "localized-smenarensky-software",
    path: "/:locale(en)/smenarensky-software",
    component: () => import("/vercel/path0/layers/modules/fubex-pages/pages/smenarensky-software.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-alert",
    path: "/:locale(en)/styleguide/alert",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/alert.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-badge",
    path: "/:locale(en)/styleguide/badge",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/badge.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-button",
    path: "/:locale(en)/styleguide/button",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/button.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-checkbox",
    path: "/:locale(en)/styleguide/checkbox",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/checkbox.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-dialog",
    path: "/:locale(en)/styleguide/dialog",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/dialog.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-file-input",
    path: "/:locale(en)/styleguide/file-input",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/file-input.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-icon",
    path: "/:locale(en)/styleguide/icon",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/icon.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide",
    path: "/:locale(en)/styleguide",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/index.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-input",
    path: "/:locale(en)/styleguide/input",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/input.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-label",
    path: "/:locale(en)/styleguide/label",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/label.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-radio",
    path: "/:locale(en)/styleguide/radio",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/radio.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-select",
    path: "/:locale(en)/styleguide/select",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/select.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-shadows",
    path: "/:locale(en)/styleguide/shadows",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/shadows.vue").then(m => m.default || m)
  },
  {
    name: "localized-styleguide-theme",
    path: "/:locale(en)/styleguide/theme",
    component: () => import("/vercel/path0/layers/components/fubex-components/pages/styleguide/theme/index.vue").then(m => m.default || m)
  },
  {
    name: "localized-update-password",
    path: "/:locale(en)/update-password",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: component_45stubW0C3hXfj3RMeta?.name,
    path: "/profile/",
    component: component_45stubW0C3hXfj3R
  }
]